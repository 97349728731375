import _ from "lodash"
import { api } from "~/helpers/StorageHelper"

export const {
    state,
    mutations,
    actions: apiActions,
} = api(
    {
        // Animals
        animals: "/animal/",
        selectedAnimalUrl: null,

        // Deliveries
        recurringDeliveries: "/recurringdelivery/",
        subscriptionItems: "/recurringdeliveryitem/?limit=1000",
        deliveries: "/delivery/?limit=1000",
        deliveryitems: "/deliveryitem/?limit=1000",

        // Customer
        customer: "/customer/",
        addresses: "/address/",
        newsletter: false,
        visits: [],

        // Ehealth
        reminders: "/reminder/",
        reminderCompletions: "/remindercompletion/",
        reminderDeletions: "/reminderdeletion/",

        // Checkout
        formulas: [],
        compositions: [],
        currentCart: null,
        upsells: null,
        productsOptions: [],
        coupon: null,

        // Questionnaire
        currentFlow: null,

        // Dashboard
        pricingRules: "/pricingrules/?limit=1000",
        testbag: false,

        // Static - Checkout
        feedbackQuestions: "/feedbackquestion/",

        // Static - Localization
        languages: "/languages/",
        countries: "/country/",
        languageUpdatedByUser: false,

        // Static - Questionnaire
        breeds: "/breeds/",
        catBreeds: "/breeds/?type=cat",
        dogBreeds: "/breeds/?type=dog",

        // Static - Cancellation flow
        surveys: "/surveys/",
        surveyCategories: "/surveycategories/?limit=1000",
        surveyQuestions: "/surveyquestions/?limit=1000",
        surveyAnswers: "/surveyanswers/?limit=1000",
        recommendations: "/recommendations/?limit=1000",
        surveyAnswersSubmissions: "/surveyanswerssubmissions/?limit=1000",
        surveyAnswerRecommendationSubmissions: "/surveyanswerrecommendationsubmissions/?limit=1000",

        // Static - Eshop
        productCategories: "/product-category/?limit=1000&exclude=shop_sections,shop_recommended_products",
        productCategoriesCache: "/product-category/?limit=6&exclude=shop_sections,shop_recommended_products",
        shippingPrices: "/shippingprices/",
        productTypes: "/shopmaincategory/",
        allergies: "/allergy/",
        ages: "/animalage/",
        shopCategories: "/shopcategory/",
        shopUpsells: "/shopupsell/",
    },
    ["delivery", "customer"],
)

export const actions = {
    ...apiActions,
    // Legacy animals
    async "animals/get"(context, payload) {
        let result = await apiActions["animals/get"].bind(this)(context, payload)
        let animals = context.rootState.api?.animals
        if (!context.state.selectedAnimalUrl && animals?.length) await context.commit("selectedAnimalUrl/set", animals[0].url)
        return result
    },
    async "animals/post"(context, payload) {
        let result = await apiActions["animals/post"].bind(this)(context, payload)
        await context.commit("selectedAnimalUrl/set", result.url)
        return result
    },
    async "animals/delete"(context, payload) {
        let result = await apiActions["animals/delete"].bind(this)(context, payload)
        let pets = context.rootState.api.animals.filter(x => x.url !== payload.url)
        if (context.state.selectedAnimalUrl === payload.url) await context.commit("selectedAnimalUrl/set", pets?.[0]?.url || null)
        return result
    },
    async "catBreeds/get"(context, payload) {
        let result = await apiActions["catBreeds/get"].bind(this)(context, payload)
        await context.commit("breeds/set", result)
        return result
    },
    async "dogBreeds/get"(context, payload) {
        let result = await apiActions["dogBreeds/get"].bind(this)(context, payload)
        await context.commit("breeds/set", result)
        return result
    },
}

export const getters = {
    animals(state) {
        return state.animals
    },
    countries(state) {
        return state.countries
    },
    currentCountry(state) {
        return (
            state.countries.find(country => window.location.href.includes(country.frontend_domain_name)) ||
            state.countries.find(country => country.short_name.toUpperCase() === "BE") || {
                language_codes: [],
            }
        )
    },
    customer(state) {
        return {
            credit: 0,
            ...state.customer,
            get preferred_country() {
                return state.countries.find(country => country.url === this.prefered_country) || {}
            },
        }
    },
    feedbackQuestions(state) {
        return state.feedbackQuestions || []
    },
    hasActiveSubscription(state, getters) {
        return getters.subscriptions[0]?.subscription_status === "active"
    },
    subscriptions(state) {
        return state.recurringDeliveries
    },
    sortedCouponSequences: state => {
        if (!state.coupon || !state.coupon.sequences) return []
        return [...state.coupon.sequences].sort((a, b) => a.order - b.order)
    },
}
